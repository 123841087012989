import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {};
  },
  async created() {
    const columns = await this.getConfigList('dms_promotion_policy');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'enableStatus') {
        rowData.formatter = ({ row }) => (row.enableStatus === '009' ? '启用' : '禁用');
      }
      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => (row.updateDate == null ? '' : `${row.updateDate} ${row.updateDateSecond}`);
      }
      return rowData;
    });
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 3
      // 审批中 2
      // 活动关闭 6
      // 流程追回 5
      // 驳回 4
      const { bpmStatus, processCode } = row;
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (
        bpmStatus
        && code === 'edit'
        && (bpmStatus === '5'
          || bpmStatus === '6'
          || bpmStatus === '2'
          || bpmStatus === '3')
      ) {
        return false;
      }
      if (
        bpmStatus
        && code === 'submit_approval'
        && (bpmStatus === '6'
          || bpmStatus === '2'
          || bpmStatus === '3')
      ) {
        return false;
      }

      return true;
    },
    beforeClickCell({ val, row }) {
      if (row.promotionPolicyStatus === 'finished') {
        this.$message.error('已结束时不可编辑');
        return false;
      }
      return { val, row };
    },
    modalClick({ val, row }) {
      const { code, name } = val;
      console.log(row);

      if (code === 'add' || code === 'edit' || code === 'view') {
        this.modalConfig.title = name || '查看';
        this.formConfig = {
          code,
          row,
        };

        this.openFull();
      } else if (code === 'submit_approval') {
        request.get(`/dms/promotion/v2/findDetailsById/${row.id}`).then((res) => {
          if (res.success) {
            const rowData = { ...res.result };

            rowData.businessId = rowData.id;
            rowData.businessNo = rowData.promotionPolicyCode;
            rowData.timestamp = new Date().getTime();
            this.onSubmit({ row: rowData, submitUrl: '/dms/promotion/v2/submit' }, this.approve);
          }
        });
      }
      return true;
    },
    approve(row, formData) {
      return {
        ...row,
        ...formData,
        processKey: formData.workFlowKey,
      };
    },
    // 保存回调
    onSubmit(params, fn) {
      const configParams = {
        ...params,
        code: 'approve',
        resetData: fn || this.resetData,
      };

      this.$emit('approve', configParams);
    },
    resetData(row, formData) {
      return {
        activitiBusinessVo: {
          attachmentVos: formData.activityFileList,
          processKey: formData.workFlowKey,
          processName: formData.workFlowName,
          processTitle: formData.title,
          remark: formData.approveRemarks,
        },
        promotionEditVo: row,
      };
    },
  },
};

<!--
 * @Autor: Nehc
 * @Date: 2020-12-03 11:20:04
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 15:53:42
-->
<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import IncludingOrNo from './promotion_area';
import Products from './promotion_product';
import Rules from './promotion_rule';

formCreate.component('IncludingOrNo', IncludingOrNo);
formCreate.component('Products', Products);
formCreate.component('Rules', Rules);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      cusName: '',
      cusInfo: {},
      templateId: '',
      templateCode: '',
      templateName: '',
      disableComponents: [],
    };
  },

  async mounted() {
    // 促销新增基础表单
    this.rule = await this.getFormRule('dms_prom_policy_addInfo');
    await this.rule.forEach((v) => {
      const rowData = v;
      if (rowData.field === 'muban') {
        rowData.restful = '/dms/promotion/template/page';
        rowData.headers = { functionCode: 'select-meet-conditions' };
        rowData.refresh = true;
        rowData.optionsKey = {
          label: 'templateName',
          value: 'templateCode',
        };
        rowData.on.change = (val) => {
          this.templateId = rowData.options.filter((a) => a.templateCode === val)[0].id;
          this.templateName = rowData.options.filter((a) => a.templateCode === val)[0].templateName;
          request
            .get('/dms/promotion/policy/editinfo', {
              templateId: rowData.options.filter((a) => a.templateCode === val)[0].id,
            })
            .then((res) => {
              this.updateRules({
                products: {
                  props: {
                    type: res.result.templateEditVo.promotionProduct,
                    promotionProduct: res.result.promotionPolicyProduct,
                  },
                },
              });
              this.updateRules({
                rules: {
                  props: {
                    promotionRule: res.result.promotionPolicyRule,
                    promotionRuleJson: res.result.promotionPolicyRule,
                  },
                },
              });
              this.updateRules({
                nonOrNo: {
                  props: {
                    promotionScope: res.result.promotionPolicyScope,
                  },
                },
              });
            });
        };
      } else if (rowData.field === 'promotionPolicyCode') {
        rowData.validate = [
          ...rowData.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      } else if (rowData.field === 'endTime' || rowData.field === 'beginTime') {
        rowData.props = {
          ...rowData.props,
          relation: {
            beginField: 'beginTime',
            endField: 'endTime',
          },
        };
      } else if (rowData.field === 'scopeType') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.getRule('nonOrNo').props.scopeType = rowData.options.find(
              (a) => a.label === val,
            ).value;
          },
          optionCallback: () => {
            if (this.formConfig.code === 'add') {
              this.setValue({
                [`${rowData.field}`]: 'customer',
              });
            }
          },
        };
      } else if (rowData.field === 'firstOrder' || rowData.field === 'multipleable') {
        rowData.on = {
          ...rowData.on,
          optionCallback: () => {
            this.setValue({
              [`${rowData.field}`]: 'N',
            });
          },
        };
      }
    });
    // 促销商品
    this.rule.push({
      type: 'Products',
      field: 'products',
      title: '',
      props: {
        functionCode: '',
        selection: [],
        idKey: 'id',
        promotionProduct: {},
        type: '',
        notView:
          this.formConfig.code !== 'view' && this.formConfig.row.promotionPolicyStatus !== 'running',
      },
      value: {
        originTableData: [],
        giftTableData: [],
        bothTableData: [],
        productsType: '',
        productsNum: '',
      },
    });
    // 促销规则
    this.rule.push({
      type: 'Rules',
      field: 'rules',
      title: '',
      props: {
        functionCode: '',
        selection: [],
        idKey: 'id',
        type: '',
        promotionRule: {},
        promotionRuleJson: {},
        notView:
          this.formConfig.code !== 'view' && this.formConfig.row.promotionPolicyStatus !== 'running',
      },
      value: {
        rules: [],
      },
    });
    // 促销范围
    this.rule.push({
      type: 'IncludingOrNo',
      field: 'nonOrNo',
      title: '',
      props: {
        functionCode: '',
        selection: [],
        idKey: 'id',
        scopeType: 'customer',
        promotionScope: {},
        notView:
          this.formConfig.code !== 'view'
          && this.formConfig.row.promotionPolicyStatus !== 'running',
        isAdd: true,
      },
      value: {
        orgs: [],
        nonOrgs: [],
        customers: [],
        nonCustomers: [],
      },
    });
    this.reload(this.rule);
    if (this.formConfig.code !== 'add') {
      this.getData();
    }
    // 编辑控制不可见性
    if (this.formConfig.row.promotionPolicyStatus === 'running') {
      this.disableComponents.push(
        'promotionPolicyCode',
        'promotionPolicyName',
        'muban',
        'beginTime',
        'multipleable',
        'firstOrder',
        'scopeType',
        'description',
        'actPlanNo',
        'feeRate',
        'chanel',
        'saleCompanyCode',
      );
    } else if (this.formConfig.row.promotionPolicyStatus === 'waiting') {
      this.disableComponents.push('promotionPolicyCode');
    } else if (this.formConfig.code === 'view') {
      this.disableComponents.push(
        'promotionPolicyCode',
        'promotionPolicyName',
        'muban',
        'beginTime',
        'endTime',
        'multipleable',
        'firstOrder',
        'scopeType',
        'description',
        'actPlanNo',
        'feeRate',
        'chanel',
        'saleCompanyCode',
      );
    }
    this.disableComponents.map((item) => {
      this.getRule(item).props.disabled = true;
      return true;
    });
  },

  methods: {
    getData() {
      request
        .get('/dms/promotion/policy/editinfo/', {
          id: this.formConfig.row.id,
        })
        .then((res) => {
          this.updateRules({
            products: {
              props: {
                type: res.result.templateEditVo.promotionProduct,
              },
            },
          });
          const originTableData = [];
          const giftTableData = [];
          const bothTableData = [];
          switch (Number(res.result.templateEditVo.promotionProduct)) {
            // 仅含本品
            case 0: {
              res.result.promotionPolicyProduct.productsCurrentY.map((v) => {
                originTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                });
                return v;
              });
              break;
            }
            case 3: {
              res.result.promotionPolicyProduct.productsCurrentY.map((v) => {
                originTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                });
                return v;
              });
              break;
            }
            // 本品和赠品
            case 1: {
              res.result.promotionPolicyProduct.productsCurrentY.map((v) => {
                originTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                });
                return v;
              });
              res.result.promotionPolicyProduct.productsCurrentN.map((v) => {
                giftTableData.push({
                  giftCode: v.productCode,
                  giftName: v.productName,
                });
                return v;
              });
              break;
            }
            // 本品映射赠品
            case 2: {
              // res.result.promotionPolicyProduct.productsCurrentY.map((v, k) => {
              //   const a = res.result.promotionPolicyProduct.productsCurrentN[k];
              //   bothTableData.push({
              //     productName: v.productName,
              //     productCode: v.productCode,
              //     productOrderQty: v.productOrderQty,
              //     productOrderQtyType: v.productOrderQtyType,
              //     giftCode: a.productCode,
              //     giftName: a.productName,
              //   });
              //   return v;
              // });
              res.result.promotionPolicyProduct.productsCurrentY.map((v) => {
                bothTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                  giftCode: v.productCodeGift,
                  giftName: v.productNameGift,
                });
                return v;
              });
              break;
            }
            default: {
              break;
            }
          }
          const data = {
            ...res.result,
            scopeType: res.result.scopeType,
            beginTime: res.result.beginTime,
            endTime: res.result.endTime,
            description: res.result.description,
            firstOrder: res.result.firstOrder,
            multipleable: res.result.multipleable,
            promotionPolicyCode: res.result.promotionPolicyCode,
            promotionPolicyName: res.result.promotionPolicyName,
            muban: res.result.templateCode,
            templateId: res.result.templateId,
            templateName: res.result.templateName,
            actPlanNo: res.result.actPlanNo,
            feeRate: res.result.feeRate,
            chanel: res.result.chanel,
            saleCompanyCode: res.result.saleCompanyCode,
            // saleCompanyName: res.result.saleCompanyName,
            products: {
              originTableData,
              giftTableData,
              bothTableData,
              productsType: res.result.productsOrderQtyType,
              productsNum: res.result.productsOrderQty,
            },
            rules: {
              rules: res.result.promotionPolicyRule,
            },
          };
          this.getRule('nonOrNo').props.scopeType = res.result.scopeType;
          this.getRule('nonOrNo').props.isAdd = false;
          if (res.result.scopeType === 'customer') {
            data.nonOrNo = {
              orgs: res.result.promotionPolicyScope.scopesAsOrg.containY || [],
              nonOrgs: res.result.promotionPolicyScope.scopesAsOrg.containN || [],
              customers: res.result.promotionPolicyScope.scopesAsCust.containY || [],
              nonCustomers: res.result.promotionPolicyScope.scopesAsCust.containN || [],
            };
          } else {
            data.nonOrNo = {
              orgs: res.result.promotionPolicyScope.scopesAsTerminalOrg.containY || [],
              nonOrgs: res.result.promotionPolicyScope.scopesAsTerminalOrg.containN || [],
              customers: res.result.promotionPolicyScope.scopesAsTerminal.containY || [],
              nonCustomers: res.result.promotionPolicyScope.scopesAsTerminal.containN || [],
            };
          }
          this.setValue(data);
        });
    },
    async submit(e) {
      const requestUrl = '/dms/promotion/policy';
      const a = []; // 赠品表
      // console.log(this.getFormData().rules.rules);
      this.getFormData().products.giftTableData.map((v) => {
        a.push({
          productCode: v.giftCode,
          productName: v.giftName,
        });
        return true;
      });
      const b = []; // 本品表
      this.getFormData().products.originTableData.map((v) => {
        b.push({
          productCode: v.productCode,
          productName: v.productName,
          productOrderQty: v.productOrderQty,
          productOrderQtyType: v.productOrderQtyType,
        });
        return true;
      });
      // 映射表
      this.getFormData().products.bothTableData.map((v) => {
        // a.push({
        //   productCode: v.giftCode,
        //   productName: v.giftName,
        // });
        b.push({
          productNameGift: v.giftName,
          productCodeGift: v.giftCode,
          productName: v.productName,
          productCode: v.productCode,
          productOrderQty: v.productOrderQty,
          productOrderQtyType: v.productOrderQtyType,
        });
        return true;
      });
      const nonCustomers = []; // 不包含的客户或者终端
      this.getFormData().nonOrNo.nonCustomers.map((v) => {
        if (this.getFormData().scopeType === 'customer') {
          nonCustomers.push({
            scopeCode: v.customerCode,
            scopeName: v.customerName,
          });
        } else {
          nonCustomers.push({
            scopeCode: v.terminalCode,
            scopeName: v.terminalName,
          });
        }

        return true;
      });
      const customers = []; // 包含的客户或者终端
      this.getFormData().nonOrNo.customers.map((v) => {
        if (this.getFormData().scopeType === 'customer') {
          customers.push({
            scopeCode: v.customerCode,
            scopeName: v.customerName,
          });
        } else {
          customers.push({
            scopeCode: v.terminalCode,
            scopeName: v.terminalName,
          });
        }

        return true;
      });
      const nonOrgs = []; // 不包含的组织
      this.getFormData().nonOrNo.nonOrgs.map((v) => {
        nonOrgs.push({
          scopeCode: v.orgCode,
          scopeName: v.orgName,
        });
        return true;
      });
      const orgs = []; // 包含的组织
      this.getFormData().nonOrNo.orgs.map((v) => {
        orgs.push({
          scopeCode: v.orgCode,
          scopeName: v.orgName,
        });
        return true;
      });
      let g = []; // 规则中计算规则
      if (
        this.getFormData().rules.rules.rulesAsCalculate.find((v) => v.selected === true) === undefined
      ) {
        g = [];
      } else {
        g.push(this.getFormData().rules.rules.rulesAsCalculate.find((v) => v.selected === true));
      }
      let h = []; // 规则中满足条件
      if (
        this.getFormData().rules.rules.rulesAsCondition.find((v) => v.selected === true) === undefined
      ) {
        h = [];
      } else {
        h.push(this.getFormData().rules.rules.rulesAsCondition.find((v) => v.selected === true));
      }
      const k = []; // 规则中限量控制
      this.getFormData().rules.rules.rulesAsLimited.map((v) => {
        const rowData = v;
        if (v.selected === true) {
          k.push(rowData);
        }
        return true;
      });
      const params = {
        scopeType: this.getFormData().scopeType,
        promotionPolicyCode: this.getFormData().promotionPolicyCode,
        promotionPolicyName: this.getFormData().promotionPolicyName,
        templateCode: this.getFormData().muban,
        templateId: this.templateId || this.getFormData().templateId,
        templateName: this.templateName || this.getFormData().templateName,
        beginTime: this.getFormData().beginTime,
        endTime: this.getFormData().endTime,
        firstOrder: this.getFormData().firstOrder,
        multipleable: this.getFormData().multipleable,
        description: this.getFormData().description,
        productsOrderQtyType: this.getFormData().products.productsType,
        productsOrderQty: this.getFormData().products.productsNum,
        actPlanNo: this.getFormData().actPlanNo,
        feeRate: this.getFormData().feeRate,
        chanel: this.getFormData().chanel,
        saleCompanyCode: this.getFormData().saleCompanyCode,
        // saleCompanyName: this.getFormData().saleCompanyName,
        id: this.formConfig.row.id || '',
        promotionPolicyProduct: {
          productsCurrentN: a,
          productsCurrentY: b,
        },
        promotionPolicyRule: {
          rulesAsCalculate: g,
          rulesAsCondition: h,
          rulesAsLimited: k,
        },
        // promotionPolicyScope: {
        //   scopesAsCust: {
        //     containN: nonCustomers,
        //     containY: customers,

        //   },
        //   scopesAsOrg: {
        //     containN: nonOrgs,
        //     containY: orgs,
        //   },
        // },
      };
      if (this.getFormData().scopeType === 'customer') {
        params.promotionPolicyScope = {
          scopesAsCust: {
            containN: nonCustomers,
            containY: customers,
          },
          scopesAsOrg: {
            containN: nonOrgs,
            containY: orgs,
          },
        };
      } else {
        params.promotionPolicyScope = {
          scopesAsTerminal: {
            containN: nonCustomers,
            containY: customers,
          },
          scopesAsTerminalOrg: {
            containN: nonOrgs,
            containY: orgs,
          },
        };
      }
      console.log(params);
      params.bpmStatus = '1';

      if (this.formConfig.code === 'edit') {
        params.bpmStatus = '2';
      }
      if (e === 2) {
        this.$emit('submit', {
          row: params,
          submitUrl: requestUrl,
        });
      } else {
        request.post(requestUrl, params).then((res) => {
          if (res.code === 200) {
            this.$emit('onClose');
            this.$emit('onGetList');
          } else {
            this.$message.error(res.messge);
          }
        });
      }
    },
  },
};
</script>

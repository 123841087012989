var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _c("hr"),
      _c("h3", [_vm._v("促销商品")]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "2",
              expression: "type !== '2'",
            },
          ],
        },
        [
          _vm.notView
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addTable("origin")
                                },
                              },
                            },
                            [_vm._v("添加本品")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  876953543
                ),
              })
            : _vm._e(),
          _c(
            "span",
            [
              _c("label", [_vm._v("产品组合起订量：")]),
              _c(
                "el-select",
                {
                  staticClass: "renderSelect",
                  attrs: { placeholder: "请选择类型", disabled: !_vm.notView },
                  on: { change: _vm.groupType },
                  model: {
                    value: _vm.productGroupType,
                    callback: function ($$v) {
                      _vm.productGroupType = $$v
                    },
                    expression: "productGroupType",
                  },
                },
                _vm._l(_vm.ptypeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c("el-input", {
                staticClass: "renderInput",
                attrs: {
                  placeholder: _vm.productGroupNumCallback(
                    _vm.productGroupType
                  ),
                  disabled: !_vm.notView,
                },
                on: { change: _vm.groupNum },
                model: {
                  value: _vm.productGroupNum,
                  callback: function ($$v) {
                    _vm.productGroupNum = $$v
                  },
                  expression: "productGroupNum",
                },
              }),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.type !== "2",
                  expression: "type !== '2'",
                },
              ],
              attrs: {
                align: _vm.allAlign,
                data: _vm.originTableData,
                "edit-config": { trigger: "click", mode: "cell" },
              },
              model: {
                value: _vm.originTableData,
                callback: function ($$v) {
                  _vm.originTableData = $$v
                },
                expression: "originTableData",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { field: "productCode", title: "本品编码" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "productName", title: "本品名称" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "productOrderQtyType",
                  title: "单品起订量类型",
                  "edit-render": {
                    name: "$select",
                    options: this.ptypeList,
                    props: { disabled: !_vm.notView },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "productOrderQty",
                  title: "单品起订量",
                  "edit-render": {
                    name: "$input",
                    props: { type: "number", min: 0, disabled: !_vm.notView },
                  },
                },
              }),
              _vm.notView
                ? _c("vxe-table-column", {
                    attrs: { title: "操作", width: "100", "show-overflow": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "vxe-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable(row, "origin")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1522083466
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "1",
              expression: "type === '1'",
            },
          ],
        },
        [
          _vm.notView
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addTable("gift")
                                },
                              },
                            },
                            [_vm._v("添加赠品")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3685088387
                ),
              })
            : _vm._e(),
          _c(
            "vxe-table",
            {
              attrs: { align: _vm.allAlign, data: _vm.giftTableData },
              model: {
                value: _vm.giftTableData,
                callback: function ($$v) {
                  _vm.giftTableData = $$v
                },
                expression: "giftTableData",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { field: "giftCode", title: "赠品编码" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "giftName", title: "赠品名称" },
              }),
              _vm.notView
                ? _c("vxe-table-column", {
                    attrs: { title: "操作", width: "100", "show-overflow": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "vxe-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable(row, "gift")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1010346242
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "2",
              expression: "type === '2'",
            },
          ],
        },
        [
          _vm.notView
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addTable("both")
                                },
                              },
                            },
                            [_vm._v("添加本品")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4178591618
                ),
              })
            : _vm._e(),
          _c(
            "span",
            [
              _c("label", [_vm._v("产品组合起订量：")]),
              _c(
                "el-select",
                {
                  staticClass: "renderSelect",
                  attrs: { placeholder: "请选择类型", disabled: !_vm.notView },
                  on: { change: _vm.groupType },
                  model: {
                    value: _vm.productGroupType,
                    callback: function ($$v) {
                      _vm.productGroupType = $$v
                    },
                    expression: "productGroupType",
                  },
                },
                _vm._l(_vm.ptypeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c("el-input", {
                staticClass: "renderInput",
                attrs: {
                  placeholder: _vm.productGroupNumCallback(
                    _vm.productGroupType
                  ),
                  disabled: !_vm.notView,
                },
                on: { change: _vm.groupNum },
                model: {
                  value: _vm.productGroupNum,
                  callback: function ($$v) {
                    _vm.productGroupNum = $$v
                  },
                  expression: "productGroupNum",
                },
              }),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              attrs: {
                align: _vm.allAlign,
                data: _vm.bothTableData,
                "edit-config": { trigger: "click", mode: "cell" },
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { field: "productCode", title: "本品编码" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "productName", title: "本品名称" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "productOrderQtyType",
                  title: "单品起订量类型",
                  editRender: {
                    name: "ElSelect",
                    options: this.ptypeList,
                    props: { disabled: !_vm.notView },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "productOrderQty",
                  title: "单品起订量",
                  "edit-render": {
                    name: "$input",
                    props: { type: "number", min: 0, disabled: !_vm.notView },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "giftCode",
                  title: "赠品编码",
                  "edit-render": {
                    name: "$input",
                    events: {
                      focus: function (rowIndex) {
                        return this$1.getGift(rowIndex, "in")
                      },
                    },
                    props: { disabled: !_vm.notView },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: { field: "giftName", title: "赠品名称" },
              }),
              _vm.notView
                ? _c("vxe-table-column", {
                    attrs: { title: "操作", width: "100", "show-overflow": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "vxe-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable(row, "both")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3695408047
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _c("hr"),
      _c("h3", [_vm._v("促销范围")]),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "组织", name: "first" } }),
          _c("el-tab-pane", {
            attrs: {
              label: this.scopeType === "customer" ? "客户" : "终端",
              name: "second",
            },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 20, offset: 1 } },
            [
              _c("h5", [_vm._v("包含")]),
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("")
                                    },
                                  },
                                },
                                [_vm._v("添加")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.beforeDelete("first")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1948761062
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.firstTableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("firstSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("firstSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _vm._l(_vm.firstColumn, function (config, index) {
                    return _c(
                      "vxe-table-column",
                      _vm._b({ key: index }, "vxe-table-column", config, false)
                    )
                  }),
                ],
                2
              ),
              _c("h5", [_vm._v("非包含")]),
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("non")
                                    },
                                  },
                                },
                                [_vm._v("添加")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.beforeDelete("second")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3398192739
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.secondTableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("secondSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("secondSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _vm._l(_vm.firstColumn, function (config, index) {
                    return _c(
                      "vxe-table-column",
                      _vm._b({ key: index }, "vxe-table-column", config, false)
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
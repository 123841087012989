<template>
  <div class="list-box">
    <hr/>
    <h3>促销范围</h3>
    <el-tabs v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="组织"
                   name="first"></el-tab-pane>
      <el-tab-pane :label="this.scopeType === 'customer' ? '客户' : '终端'"
                   name="second"></el-tab-pane>
    </el-tabs>

    <el-row>
      <el-col :span="20"
              :offset="1">
    <h5>包含</h5>
    <vxe-toolbar v-if="notView">
      <template v-slot:buttons>
        <vxe-button @click="addTable('')">添加</vxe-button>
        <vxe-button @click="beforeDelete('first')">删除</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign"
    @checkbox-all="({records}) => selectAllEvent('firstSelect',records)"
               @checkbox-change="({records}) => selectChangeEvent('firstSelect',records)"
               :data="firstTableData">
      <vxe-table-column type="checkbox"
                        width="50"></vxe-table-column>
      <vxe-table-column v-for="(config, index) in firstColumn"
                        :key="index"
                        v-bind="config"></vxe-table-column>
    </vxe-table>
    <h5>非包含</h5>
    <vxe-toolbar v-if="notView">
      <template v-slot:buttons>
        <vxe-button @click="addTable('non')">添加</vxe-button>
        <vxe-button @click="beforeDelete('second')">删除</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign"
    @checkbox-all="({records}) => selectAllEvent('secondSelect',records)"
               @checkbox-change="({records}) => selectChangeEvent('secondSelect',records)"
               :data="secondTableData">
      <vxe-table-column type="checkbox"
                        width="50"></vxe-table-column>
      <vxe-table-column v-for="(config, index) in firstColumn"
                        :key="index"
                        v-bind="config"></vxe-table-column>
    </vxe-table>
      </el-col>
    </el-row>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect"/>
  </div>

</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

const orgColumn = [
  { field: 'orgCode', title: '组织编码' },
  { field: 'orgName', title: '组织名称' },
];
const cusColumn = [
  { field: 'customerCode', title: '客户编码' },
  { field: 'customerName', title: '客户名称' },
];
const terColumn = [
  { field: 'terminalCode', title: '终端编码' },
  { field: 'terminalName', title: '终端名称' },
];

export default {
  name: 'NoticeManageTableForm',
  components: {
    SelectConfig,
  },
  props: {
    scopeType: String,
    value: Object,
    notView: Boolean,
    isAdd: Boolean,
  },
  watch: {
    scopeType(n) {
      console.log(this.notView, '===========');
      if (this.tabType === 'second') {
        if (n === 'terminal') {
          this.firstColumn = terColumn;
        } else if (n === 'customer') {
          this.firstColumn = cusColumn;
        }
        this.firstTableData = [];
        this.secondTableData = [];
      }
      if (this.isAdd) {
        this.value.customers = [];
        this.value.nonCustomers = [];
      }
    },
    value() {
      if (this.scopeType === 'terminal') {
        this.value.customers.map((v) => {
          const rowData = v;
          rowData.terminalCode = v.scopeCode;
          rowData.terminalName = v.scopeName;
          return rowData;
        });
        this.value.nonCustomers.map((v) => {
          const rowData = v;
          rowData.terminalCode = v.scopeCode;
          rowData.terminalName = v.scopeName;
          return rowData;
        });
      } else {
        this.value.customers.map((v) => {
          const rowData = v;
          rowData.customerCode = v.scopeCode;
          rowData.customerName = v.scopeName;
          return rowData;
        });
        this.value.nonCustomers.map((v) => {
          const rowData = v;
          rowData.customerCode = v.scopeCode;
          rowData.customerName = v.scopeName;
          return rowData;
        });
      }

      this.value.nonOrgs.map((v) => {
        const rowData = v;
        rowData.orgCode = v.scopeCode;
        rowData.orgName = v.scopeName;
        return rowData;
      });
      this.value.orgs.map((v) => {
        const rowData = v;
        rowData.orgCode = v.scopeCode;
        rowData.orgName = v.scopeName;
        return rowData;
      });
      if (this.tabType === 'first') {
        this.firstTableData = this.value.orgs;
        this.secondTableData = this.value.nonOrgs;
      }
    },
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      formConfig: {
        tableUrl: '',
      }, // 弹框数据
      formName: 'Form',
      activeName: 'first',
      allAlign: null,
      firstColumn: orgColumn,
      firstTableData: [],
      secondTableData: [],
      modalType: '', // 弹框包含非包含
      tabType: 'first',
      firstSelect: [],
      secondSelect: [],
    };
  },
  created() {

  },
  methods: {
    handleClick(tab) {
      this.tabType = tab.name;
      if (tab.name === 'second') {
        this.firstColumn = this.scopeType === 'customer' ? cusColumn : terColumn;
        this.firstTableData = this.value.customers;
        this.secondTableData = this.value.nonCustomers;
      } else if (tab.name === 'first') {
        this.firstColumn = orgColumn;
        this.firstTableData = this.value.orgs;
        this.secondTableData = this.value.nonOrgs;
      }
    },
    addTable(val) {
      this.modalType = val || '';
      let params;
      if (this.tabType === 'first') {
        const selectionList = [];
        const list = val ? this.secondTableData : this.firstTableData;
        list.forEach((v) => {
          selectionList.push(v.orgCode);
        });
        params = {
          functionCode: 'dms_noice_org',
          data: list,
          selectionList,
          idKey: 'orgCode',
        };
      } else if (this.tabType === 'second') {
        if (this.scopeType === 'customer') {
          const selectionList = [];
          const list = val ? this.secondTableData : this.firstTableData;
          list.forEach((v) => {
            selectionList.push(v.customerCode);
          });
          params = {
            functionCode: 'dms_noitce_cus',
            data: list,
            selectionList,
            idKey: 'customerCode',
          };
        } else {
          const selectionList = [];
          const list = val ? this.secondTableData : this.firstTableData;
          list.forEach((v) => {
            selectionList.push(v.customerCode);
          });
          params = {
            functionCode: 'dms_noice_terminal',
            data: list,
            selectionList,
            idKey: 'terminalCode',
          };
        }
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      if (this.modalType) {
        if (this.tabType === 'second') {
          this.value.nonCustomers = val;
        } else if (this.tabType === 'first') {
          this.value.nonOrgs = val;
        }
        this.secondTableData = val;
      } else {
        if (this.tabType === 'second') {
          this.value.customers = val;
        } else if (this.tabType === 'first') {
          this.value.orgs = val;
        }
        this.firstTableData = val;
      }
    },
    beforeDelete(val) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.deleteTable(val);
      }).catch(() => {

      });
    },
    deleteTable(val) {
      const list = [];
      let code = '';
      if (this.tabType === 'second') {
        code = this.scopeType === 'customer' ? 'customerCode' : 'terminalCode';
      } else {
        code = 'orgCode';
      }
      this[`${val}TableData`].forEach((v) => {
        let notSelect = true;
        if (this[`${val}Select`].filter((a) => a[code] === v[code]).length > 0) {
          notSelect = false;
        }
        if (notSelect) {
          list.push(v);
        }
      });
      this[`${val}TableData`] = list;
      this[`${val}Select`] = [];
      if (this.tabType === 'second') {
        this.value.customers = this.firstTableData;
        this.value.nonCustomers = this.secondTableData;
      } else if (this.tabType === 'first') {
        this.value.orgs = this.firstTableData;
        this.value.nonOrgs = this.secondTableData;
      }
    },
    selectChangeEvent(val, records) {
      this[val] = records;
    },
    selectAllEvent(val, records) {
      this[val] = records;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
</style>

<!--
 * @Autor: Nehc
 * @Date: 2020-12-05 11:13:40
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-29 15:15:12
-->
<template>
  <div>
    <hr />
    <h3 class="rule-title">
      促销规则
      <el-button class="add-step" @click="addStep" v-if="notView">添加阶梯</el-button>
    </h3>
    <span>
      <label>满足条件：</label>
      <el-select
        v-model="selcetValue"
        clearable
        class="condition"
        :disabled="!notView"
        @change="showTrue"
        @clear="setValueNull"
      >
        <el-option
          v-for="item in conditions"
          :key="item.id"
          :label="item.ruleName"
          :value="item.ruleCode"
        >
        </el-option>
      </el-select>
    </span>
    <br />
    <span>
      <label>计算规则：</label>
      <el-radio-group v-model="calRadio" @change="showRTrue" :disabled="!notView">
        <div v-for="(item, index) in calculates" :key="index">
          <el-radio :label="item.ruleCode">{{ item.ruleName }}</el-radio>
        </div>
      </el-radio-group>
    </span>
    <br />
    <span>
      <RuleRender
        v-for="(item, index) in checkedControl.controlRows"
        :ladder="item"
        :key="index"
        @deleteRule="beforeDelete(index)"
        :notView="notView"
      ></RuleRender>
    </span>
    <br />
    <span>
      <label>限量控制</label>
      <el-checkbox-group v-model="limtedValue" @change="showCTrue" :disabled="!notView">
        <el-checkbox  v-for="item in ruleLimted" :key="item.id" :label="item.ruleName"></el-checkbox>
      </el-checkbox-group>
    </span>
  </div>
</template>

<script>
import RuleRender from '../rule_render/rule_render.vue';

export default {
  name: 'RulesList',
  components: {
    RuleRender,
  },
  props: {
    value: Object,
    promotionRule: Object,
    promotionRuleJson: Object,
    notView: Boolean,
  },
  watch: {
    promotionRule: {
      deep: true,
      immediate: true,
      handler(v) {
        this.value.rules = v;
        this.ruleLimted = v.rulesAsLimited;
        this.conditions = v.rulesAsCondition;
        this.calculates = v.rulesAsCalculate;
        return true;
      },
    },
    promotionRuleJson: {
      deep: true,
      immediate: true,
      handler(v) {
        this.selcetValue = '';
        this.showTrue('');
        return true;
      },
    },
    value() {
      this.conditions = this.value.rules.rulesAsCondition;
      this.calculates = this.value.rules.rulesAsCalculate;
      this.ruleLimted = this.value.rules.rulesAsLimited;
      this.checkedControl = this.value.rules.rulesAsCondition.find(
        (v) => v.selected === true,
      );
      this.selcetValue = this.value.rules.rulesAsCondition.find(
        (v) => v.selected === true,
      ).ruleCode;
      this.calRadio = this.value.rules.rulesAsCalculate.find(
        (v) => v.selected === true,
      ).ruleCode;
      this.value.rules.rulesAsLimited.map((v) => {
        if (v.selected === true) {
          this.limtedValue.push(v.ruleName);
        }
        return true;
      });
    },
  },
  data() {
    return {
      conditions: [], // res获取的条件
      calculates: [], // res获取的计算
      ruleLimted: [], // res获取的限制
      checkedControl: [], // 选中的条件
      stepModal: null,
      calRadio: '', // 计算规则单选
      selcetValue: '', // 满足条件下拉
      limtedValue: [], // 限量多选
      stepProduct: '', // 计算数量
      checkList: [], // 勾选的限量控制
    };
  },
  mounted() {},
  methods: {
    beforeDelete(index) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.deleteRule(index);
      }).catch(() => {

      });
    },
    deleteRule(index) {
      this.checkedControl.controlRows.splice(index, 1);
    },
    setValueNull(val) {
      this.value_ = null;
      this.conditions.map((v) => {
        const rowData = v;
        rowData.selected = false;
        return rowData;
      });
    },
    // 新增计算规则阶梯
    addStep() {
      // console.log(this.checkedControl.controlRows);
      const stepModal = JSON.parse(JSON.stringify(this.stepModal || ''));
      if (stepModal) {
        this.checkedControl.controlRows.push(stepModal);
      }
    },
    // 选中满足条件的下拉项
    showTrue(val) {
      this.stepModal = null;
      if (this.checkedControl.controlRows && this.checkedControl.controlRows.length > 0) {
        this.checkedControl.controlRows.splice(1, this.checkedControl.controlRows.length);
      }
      if (val) {
        this.checkedControl = this.conditions.find((v) => val === v.ruleCode);
        this.stepModal = this.checkedControl && this.checkedControl.controlRows[0];
        const con = this.conditions.filter((item) => item.ruleCode !== this.checkedControl.ruleCode);
        con.map((k) => {
          const rowData = k;
          rowData.selected = false;
          return rowData;
        });
        this.checkedControl.selected = true;
        this.$nextTick(() => {
          this.selcetValue = this.checkedControl.ruleCode;
        });
      } else {
        // this.checkedControl.controlRows = [];
      }
      // console.log(this.checkedControl.controlRows, ' this.checkedControl.controlRows');
    },

    // 选中计算规则的勾选项
    showRTrue(val) {
      this.radioControl = this.calculates.find((v) => val === v.ruleCode);
      const cal = this.calculates.filter((item) => item.ruleCode !== this.radioControl.ruleCode);
      cal.map((k) => {
        const rowData = k;
        rowData.selected = false;
        return rowData;
      });
      this.radioControl.selected = true;
    },
    // 选中限量控制的多选项
    showCTrue(val) {
      this.ruleLimted = this.ruleLimted.map((v) => {
        const item = v;
        if (val.find((k) => k === v.ruleName)) {
          item.selected = true;
          return item;
        }
        item.selected = false;
        return item;
      });
    },
  },
};
</script>

<style scoped>
.add-step {
  margin-left: 5px;
}
.condition {
  width: 30%;
  display: inline-block;
}
.renderInput {
  width: 80px;
  margin: 0 5px;
  display: inline-block;
}
.renderSelect {
  width: 120px;
  margin: 0 5px;
  display: inline-block;
}
</style>>
